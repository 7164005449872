import './App.css';
import Header from './components/Header/Header';
import MainPage from './components/content/MainPage/MainPage';
import SimpleCarousel from './components/content/SliderInfo/Slider';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AccordionInfo from './components/content/AccordionInfo/AccordionInfo';
import TechnologyStack from './components/content/TechnologyStack/TechnologyStack';
import Contacts from './components/content/Contacts/Contacts';
import ChooseCountry from './components/content/ChooseCountry/ChooseCountry';
import SilencingPage from './components/content/SilencingPage/SilencingPage';
import Dkeys from './components/content/Dkeys/Dkeys';
import ArticleDownload from './components/content/ArticleDownload/ArticleDownload';
import OpenLink from './components/content/OpenLink/OpenLink';
import ArticleCodeMP from './components/content/ArticleCodeMP/ArticleCodeMP';
import OpenLinkContainer from './components/content/OpenLink/OpenLinkContainer';
import ArticleCodeMPContainer from './components/content/ArticleCodeMP/ArticleCodeMPContainer';
import CountryPlugs from './components/content/CountryPlugs/Country Plugs';

function App() {
  return ( 
    <div className="App">
      
     <Router>
      <div className='header'>
        <Header/>
      </div>
     <div className='main'>

     
      <Routes>
      {/* <Route path='/' element={ <Dkeys/>}></Route> */}
        <Route path='/' element={ <ChooseCountry/>}></Route>
        <Route path='*' element={ <SilencingPage/>}></Route>
        <Route path='/app' element={<ArticleDownload/>}></Route>
        <Route path='/open' element={<OpenLinkContainer/>}></Route>
        <Route path='/mp' element={<ArticleCodeMPContainer/>}></Route>
        <Route path='/mp/:id' element={<ArticleCodeMPContainer/>}></Route>
        <Route path='/country/:id' element={<CountryPlugs/>}></Route>
      </Routes></div>
     </Router>
    </div> 
  );
}

export default App;

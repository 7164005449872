import React, { useState } from "react";
import s from './ChooseCountry.module.css'
// import GooglePlay from "../../../assets/Google-Play.png";
// import AppStore from "../../../assets/AppStore.png";
import download from "../../common/assets/download.png";
import CountryPlugs from "../CountryPlugs/Country Plugs";

const ChooseCountry = () => {
    const [flag, setFlag] = useState("");
    return (
        <div className={s.mainContainer}>
            <div className={s.containerInfo}>
                <div className={s.header}><span >Choose Your</span> <span className={` ${s.spanForditail}`}> Country or Region</span></div>
                <ul className={s.listCountry}>
                    <li><a className={s.linkCountry} href="/country/am">Armenia</a></li>
                    <li><a className={s.linkCountry} href="/country/br">Brazil</a></li>
                    <li><a className={s.linkCountry} href="https://bg.d-keys.info/">Bulgaria</a></li>
                    <li><a className={s.linkCountry} href="/country/eg">Egypt</a></li>
                    <li><a className={s.linkCountry} href="/country/ge">Georgia</a></li>
                    <li><a className={s.linkCountry} href="/country/in">India</a></li>
                    <li><a className={s.linkCountry} href="https://kz.d-keys.info/">Kazakhstan</a></li>
                    <li><a className={s.linkCountry} href="/country/md">Moldova</a></li>
                    <li><a className={s.linkCountry} href="/country/mn">Mongolia</a></li>
                    <li><a className={s.linkCountry} href="https://ro.d-keys.info/">Romania</a></li>
                    <li><a className={s.linkCountry} href="https://ru.d-keys.info/">Russia</a></li>
                    <li><a className={s.linkCountry} href="/country/tr">Turkey</a></li>
                    <li><a className={s.linkCountry} href="https://us.d-keys.info/">USA</a></li>
                    <li><a className={s.linkCountry} href="/country/uz">Uzbekistan</a></li>
                </ul>
            </div>
            <div className={s.containerImgDownload}>
                <a className={s.linkfordowload} href="/app"> Download app
                    <img className={s.imgDownload} src={download} alt="download app" />
                </a>
                {/* {flag&& <CountryPlugs flag={flag}></CountryPlugs>} */}
                {/* <a href="/SilencingPage" target={"_blank"}>
                    <img className={`${s.imgDownload} ${s.appStorestyle}`} src={AppStore} alt="App Store" />
                </a> */}
            </div>
        </div>
    )
}
export default ChooseCountry;
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sendDkeys from "../../common/assets/sendDkeys.png";
import indevelopment from "../../common/assets/indevelopment.png";
import mongolia from "../../common/assets/mongolia.jpg";
import uzbekistan from "../../common/assets/uzbekistan.jpg";
import s from "./CountryPlugs.module.css";
import us_mob from "../../common/assets/us_mob.jpg";
import mong_mob from "../../common/assets/mong_mob.jpg";
import armenia_mob from "../../common/assets/armenia_mob.jpg";
import armenia from "../../common/assets/armenia.jpg";
import whatsApp from "../../common/assets/whatsApp.png";
import Viber from "../../common/assets/Viber.png";
import telegram from "../../common/assets/Telegram.png";
import telephon from "../../common/assets/телефон.png";
import Map from "../../common/assets/Map.png";
const CountryPlugs = (props) => {
  //console.log(window.innerWidth);
  const [innerWidth, setinnerWidth] = useState(window.innerWidth);
  //console.log(innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setinnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let country = useParams();
  return (
    <div className={s.mainContainer}>
{
    (()=>{
        switch (country.id) {
            case "md":
            case "ge":
              return (
                  <div className={s.containerCountryDescription}>
                    <img src={sendDkeys} className={s.imgCountryes} />
                  
                  <div className={s.containetText}>
                    <p className={s.titlecountry}>
                      If you have any questions about installing D-KEYS please contact
                      our dealer
                    </p>
                    <a
                      className={s.titlecountry}
                      href={`https://www.smd.${country.id}/ `}
                    >
                      https://www.smd.{country.id}/
                    </a>
                  </div></div>
              );
            case "in":
            case "tr":
            case "eg":
            case "br":
              return (
                <div className={s.containerCountryDescription}>
                  <img className={s.imgCountryes} src={indevelopment} />
                  <p className={s.titlecountry}>
                    We are currently developing and filling the page
                  </p>
                </div>
              );
            case "mn":
              return (
                <div className={s.containerCountryDescriptionText}>
                  <span
                    style={{ marginLeft: "1px" }}
                    className={`${s.dicriptionCountry} ${s.dicriptionBlodDkeys}`}
                  >
                    суурилуулах талаарх мэдээллийг та манай дилерт хандаж авна уу:
                  </span>
        
                  <div className={s.containerdescription}>
                    <img className={`${s.iconstyle} ${s.icontelegram}`}
                      src={Map}
                    />
                    <span className={`${s.dicriptionCountry}`}>
                      Улаанбаатар хот, Чингэлтэй дүүрэг, 4-р хороо, Барилгачдын 4/4, MAX
                      TOWER, 3 давхар 303 тоот
                    </span>
                  </div>
        
                  <div className={s.containerdescription}>
                    <img className={s.iconstyle} src={telephon} />
                    <span style={{fontWeight:"bold"}}>Утас:</span>
                    <span className={`${s.dicriptionCountry}`}>
                      70116776, 94003939, 89004039
                    </span>
                  </div>
        
                  <div className={s.containerdescription}>
                    <img className={s.iconstyle} src={whatsApp} />
                    <img className={s.iconstyle} src={Viber} />
                    <img className={`${s.iconstyle} ${s.icontelegram}`}
                      src={telegram}
                    />
                    <span style={{fontWeight:"bold"}}>Чатаар холбогдох:</span>
                    <span className={`${s.dicriptionCountry}`}>
                      85055400
                    </span>
                  </div>
                </div>
              );
            case "uz":
                return (
                    <div className={s.containerCountryDescriptionText}>
                      <span
                        style={{ marginLeft: "1px" }}
                        className={`${s.dicriptionCountry} ${s.dicriptionBlodDkeys}`}>
                        -ni o'rnatish bo'yicha savollar bo'lsa, bizning dilerimizga murojaat qiling
                      </span>
            
                      <div className={s.containerdescription}>
                        <img className={`${s.iconstyle} ${s.icontelegram}`}
                          src={Map}/>
                           <span style={{fontWeight:"bold"}}>Manzil:</span>
                        <span className={`${s.dicriptionCountry}`}>
                        Toshkent sh., Chilonzor tumani, Qatartol ko‘chasi, 60B, 506-xona (5-qavat)</span>
                      </div>
            
                      <div className={s.containerdescription}>
                        <img className={s.iconstyle} src={telephon} />
                        <span style={{fontWeight:"bold"}}>Telefonlar:</span>
                        <span className={`${s.dicriptionCountry}`}>
                        (99871) 2882388, 2882398, 2733073, 2734073 
                        </span>
                      </div>
            
                      <div className={s.containerdescription}>
                        <img className={s.iconstyle} src={whatsApp} />
                        <img className={s.iconstyle} src={Viber} />
                        <img className={`${s.iconstyle} ${s.icontelegram}`}
                          src={telegram}
                        />
                        <span style={{fontWeight:"bold"}}>Messenjerlar:</span>
                        <span className={`${s.dicriptionCountry}`}>
                        998970091551
                        </span>
                      </div>
                    </div>
                  );
            case "am":
              return (
                <div className={s.containerCountryDescriptionText}>
                  {/* {innerWidth > 840 ? <img className={s.imgCountry} src={armenia} /> : <img className={s.imgCountry} src={armenia_mob}/>} */}
                  <span
                    style={{ marginLeft: "1px" }}
                    className={`${s.dicriptionCountry} ${s.dicriptionBlodDkeys}`}
                  >
                    
                    -ի հետ կապված հարցերով կարող եք զանգահարել մեր դիլերին
                  </span>
        
                  <div className={s.containerdescription}>
                    <img
                      className={`${s.iconstyle} ${s.icontelegram}`}
                      src={Map}
                    />
                    <span className={`${s.dicriptionCountry}`}>
                      հասցե՝ քաղաք Երևան, Վաղարշ Վաղարշյան փողոց 12, 4-րդ հարկ․ ձախ թև
                    </span>
                  </div>
        
                  <div className={s.containerdescription}>
                    <img className={s.iconstyle} src={telephon} />
                    <span className={`${s.dicriptionCountry} ${s.dicriptionBlodPhone}`}>
                      
                      32-00-64, 32-00-67, 27-00-64, 091-00-65-10, 095-32-00-64,
                      094-97-14-44, 099-32-00-67
                    </span>
                  </div>
        
                  <div className={s.containerdescription}>
                    <img className={s.iconstyle} src={whatsApp} />
                    <img className={s.iconstyle} src={Viber} />
                    <img
                      className={`${s.iconstyle} ${s.icontelegram}`}
                      src={telegram}
                    />
                    <span className={`${s.dicriptionCountry} ${s.dicriptionBlodNumber}`}>
                      +37443001272
                    </span>
                  </div>
                </div>
              );
              default:
                return null; // Добавьте return null на случай, если ни одно условие не сработает
            }
          })()
}
    </div>
  )
  
};

export default CountryPlugs;
